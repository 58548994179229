<template>
	<v-layout column fill-height px-0 mx-0 scroll-y>
		<v-flex v-t="'super_admin.home_title'" shrink text-xs-center my-3 display-1 />
		<v-flex grow row>
			<CardsList :pictures="false" :content="cards" />
		</v-flex>
	</v-layout>
</template>

<script>
import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'

export default {
	name: 'SuperAdminHome',
	components: {
		CardsList: () => ({
			component: import('@/components/Commons/CardsList')
		})
	},
	mixins: [SuperAdminModuleGuard],
	data: function () {
		return {
			cards: [
				{
					title: 'Arborescences',
					routeName: 'sa-documents-manager',
					img: 'fa-sitemap',
					subtitle: "Gérer le catalogue d'arborescences",
					is_active: 1
				},
				{
					title: 'Cabinets',
					routeName: 'sa-accounting-firms',
					img: 'business',
					subtitle: 'Gérer les cabinets comptables',
					is_active: 1
				},
				{
					title: 'Import Excel',
					routeName: 'sa-customer-import',
					img: 'import_export',
					subtitle: 'Importer des données pour un cabinet',
					is_active: 1
				},
				{
					title: 'Applications',
					routeName: 'sa-applications',
					img: 'apps',
					subtitle: "Gérer le catalogue d'applications",
					is_active: 1
				},
				{
					title: 'Modules',
					routeName: 'sa-modules-manager',
					img: 'web',
					subtitle: 'Gérer les modules de Welyb',
					is_active: 1
				},
				{
					title: 'Flags',
					routeName: 'sa-flags',
					img: 'flag',
					subtitle: 'Gérer les flags des arborescences',
					is_active: 1
				},
				{
					title: 'Catalogue de thèmes',
					routeName: 'sa-catalog-themes',
					img: 'bookmarks',
					subtitle: 'Gérer le catalogue de thèmes',
					is_active: 1
				},
				{
					title: 'Applications Mobiles',
					routeName: 'sa-mobile-app',
					img: 'smartphone',
					subtitle: 'Gestion automatisée',
					is_active: 1
				},
				{
					title: 'NPS Survey',
					routeName: 'sa-nps-survey',
					img: 'assessment',
					subtitle: 'Mesure du pourcentage de satisfaction clients',
					is_active: 1
				},
				{
					img: 'church',
					routeName: 'sa-support',
					subtitle: 'Retrouver toutes les actions de support disponibles',
					title: 'Actions de support'
				},
				{
					img: 'fa-shopping-cart',
					routeName: 'sa-products',
					subtitle: 'Gérer les produits de Welyb',
					title: 'Gestion des produits'
				}
			]
		}
	},
	methods: {
		goTo: function (route) {
			this.appService.goTo(route)
		}
	}
}
</script>
